.PageEditorContainer {
  height: auto;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.9);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 auto;
}
.PageEditorLabel input {
  margin: 0 auto;
}

.PageEditorLabel {
  text-align: center;
  margin: 0;
  background-color: rgba(var(--primColor), 1);
  padding: 0 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.PageEditorLabel input {
  min-width: 25%;
  width: auto;
  color: black;
  background-color: #eee;
  padding: 0 0.25rem;
  margin: 0.25rem auto;
}

.PageEditorContent {
  padding: 0.5rem;
  border-top: 1px solid #ccc;
}

.PageEditorContent .tglBox {
  padding: 0 0.5rem;
}

.singleQuestionEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
}
