:root {
  --off-color: silver;
  --on-color: #000;
}

* {
  transition: 0.5s;
}

.tglContainer {
  height: 2rem;
  border: 1px solid var(--off-color);
  border-radius: 5px;
  width: 4rem;
  display: flex;
  align-items: center;
  background-color: #e6e6e6;
  -webkit-box-shadow: inset 0px 0px 15px 3px var(--off-color);
  box-shadow: inset 0px 0px 15px 3px var(--off-color);
  overflow: hidden;
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;
}

.tglSwitch {
  height: 100%;
  width: 0.6rem;
  border: 1px solid var(--off-color);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  left: 0;
}

.tglLabelOff {
  padding: 0 5px;
  width: 4rem;
  position: absolute;
  left: 0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tglLabelOn {
  padding: 0 5px;
  width: 4rem;
  position: absolute;
  left: -4rem;
  color: white;
  background-color: var(--on-color);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tglCaption {
  padding: 3px;
  min-width: 50%;
  line-height: 1rem;
}

.tglBox {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.tglBox:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
