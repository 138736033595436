@import "./pageEditor.css";

:root {
  --primColor: 12, 76, 140;
  --primDarkColor: 9, 54, 99;
  --disabledColor: 120, 120, 120;
  --disabledDarkColor: 21, 118, 214;
}

input[type="button"] {
  outline: none;
}

input[type="button"]:focus p {
  margin-bottom: 0.25rem;
}

.playerControls {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  margin: 0 0.5rem;
}

.playerControls label {
  margin: 0;
}

.playerControls h5 {
  margin: 0 0.5rem;
}

.playerControlButton {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: #ccc;
  margin: 0 0.1rem;
  border: none;
  outline: none;
  transition: 0.25s;
  outline: none !important;
}

.playerControlButton:hover {
  background-color: #ddd;
  color: #0c4bbc;
  transition: 0.25s;
}

.playerControlButton:active {
  background-color: #eee;
  transition: 0.25s;
}

.playerControlButton:hover > svg {
  color: #0c4bbc;
}

.playerControlButton svg {
  color: #0c4b8c;
  margin: 0 auto;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.btnIconStyle {
  font-size: 1rem;
  color: white;
}

.allUsersContainer {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #ccc;
}

.legend {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem 0;
  background-color: #eee;
}

.legend h5 {
  background-color: unset !important;
  border-radius: initial !important;
  border-bottom: 2px solid #aaa;
  padding: 0.25rem !important;
  margin: 0 !important;
}

.legend .legendItemContainer {
  margin: 0;
}

.legend .legendItemContainer .legendItem {
  height: auto;
  width: 100%;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.legend .legendItemContainer .legendItem p {
  min-width: 50%;
}
.legend .legendItemContainer .legendItem .legendItemProgress {
  height: 1rem;
  width: 5rem;
  /* min-width: 50%; */
  background-color: red;
  border-radius: 0.25rem;
  margin: 0 0 0 0.1rem;
}

input,
select,
textarea {
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 0.3rem;
  margin: 0.5rem;
  border: 1px solid silver;
  padding: 0.2rem;
  max-width: 100%;
}

input,
select,
.closeBtn,
textarea:focus {
  outline: none;
}

textarea {
  margin: unset;
}

.hCenter {
  display: block;
  margin: 10rem auto;
}

.adminPanelSectionLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userManagement {
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0 0.5rem;
  border-radius: 0.3rem !important;
  margin: 0.25rem 0 auto !important;
}

.userManagement p {
  margin: 0;
}

.userManagement .titleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: unset;
  color: black;
  overflow: hidden;
  padding: 0 0.5rem;
}

.userManagement .progressHolder {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progressHolder .progress {
  width: 100% !important;
  font-size: 1rem;
}

.progressHolder .progress-bar {
  overflow: revert;
  color: black;
}

.userManagementContent {
  min-height: 20rem;
  height: auto;
  overflow: hidden;
  margin-top: 0.5rem;
  background-color: #eee;
}

.adminQA {
  margin: 1rem;
  border: 2px solid silver;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
  text-align: center;
  background-color: white;
}

.adminQAtext {
  margin: 1rem;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.25rem;
  text-align: center;
}

.adminQAtext textarea {
  background-color: white;
}

.adminQABoxContent {
  min-height: 7rem;
  flex-direction: column;
  padding: 0.25rem;
}

.customButton {
  border-radius: 0.3rem;
  /* padding: 0.1rem 0.3rem; */
  padding: 0;
  margin: 0.2rem;
  background-color: rgba(var(--primColor), 0.8);
  color: white;
  display: inline-block;
  border: none;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  -webkit-transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, -webkit-transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease;
  transition: background 250ms ease-in-out, transform 150ms ease,
    -webkit-transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.customButton:hover {
  background-color: rgba(var(--primColor), 1);
}

* {
  transition: 0.5s;
}
.progress-bar {
  transition: 1s !important;
}

.college {
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
}

.collegeBadge {
  position: absolute;
  top: 0.25rem;
  right: 0.5rem;
  font-size: 0.75rem;
}

.college h5 {
  padding: 0.1rem 0.25rem;
  background-color: #ddd;
  border-radius: 0.25rem;
}

.collegeRow {
  padding: 3rem 0.5rem;
}

.collegeRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.6rem;
  padding-right: 1rem;
}

.collegeRight p {
  margin: 0;
  overflow-wrap: anywhere;
}

.loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mainContent {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  position: relative;
  animation: fadeIn 0.5s;
  background-color: rgba(0, 0, 0, 0.7);
}

.innerMainContent {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  padding-top: 6vh;
  overflow-y: auto;
}
.container-fluid {
  padding: 0;
  overflow-x: hidden;
}

.footer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  /* min-height: 10vh; */
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 5vh;
  overflow: hidden;
}

.addButton {
  border-radius: 50%;
  width: 1.5rem !important;
  height: 1.5rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0.5rem !important;
  background-color: rgba(0, 225, 00, 0.8);
  padding: 0.8rem;
  position: relative;
  transition: background 400ms;
  outline: 0;
  border: 0;
  background-position: center;
}

.addButton:hover {
  background-color: rgba(0, 245, 00, 0.8);
}

.addButton:active {
  background-color: rgba(0, 255, 00, 0.8);
}

.addButton:focus {
  outline: none;
}

.circleButton {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  margin: 0 0.2rem;
  padding: 0.8rem;
  position: relative;
  transition: background 400ms;
  outline: 0;
  border: 0;
  background-position: center;
  text-align: center;
}

.circleButton:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.circleButton:active {
  background-color: rgba(0, 0, 0, 0.1);
  background-size: 100%;
  transition: background 0s;
}

.navbar {
  transition: 0.3s;
}

.navbarFilled {
  border-bottom: 1px solid silver;

  padding: 0 !important;
  transition: 0.3s;
}

.opaque {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.transparent {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

#basic-navbar-nav {
  padding: 0.5rem !important;
}

.navbar-brand {
  padding: 0 !important;
}

.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.toast {
  flex-basis: initial;
}

.toastHolder {
  position: absolute !important;
  top: 4rem !important;
  right: 1rem !important;
  display: flex;
  flex-direction: column-reverse;
}

.customToastTime {
  margin-left: 0.5rem !important;
}
.toast-body {
  color: black !important;
}

.paper {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.vCenter {
  display: flex;

  align-items: center;
}

.hCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wizard {
  width: 75%;
  min-height: 75%;
  height: auto;
  margin: auto;
  border-radius: 0.5rem;
  position: relative;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eee;
  animation: fadeIn 0.3s;
}

.sortBy {
  display: flex;
  justify-content: space-between;
}
.sortBtn {
  padding: 0 0.5rem;
}

.titleBar {
  background-color: rgba(var(--primColor), 1);
  height: auto;
  padding: 0.3rem;
  width: 100%;
  text-align: center;
  color: #fff;
}

.titleBar .btn {
  padding: 0 0.5rem;
  line-height: 1rem;
}

.buttonBar {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.wizardCenterContent {
  display: flex;
  height: auto;
  width: auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 2rem;
  max-height: 70vh;
  overflow-y: auto;
}

.wizardCenterContent h5 {
  font-weight: 600;
}

.wizardSection {
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  padding: 0.5rem;
}

.wizardSection h5 {
  padding: 0.5rem;
  border-bottom: 1px solid silver;
}

.qa {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  max-width: 95%;
}

.qa h5 {
  background-color: rgba(var(--primColor), 0.5);
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
}

.qa:focus-within > h5 {
  background-color: #e7a817;
}

.qa input {
  margin: 0;
}

.sectionTitle {
  font-size: 2rem;
  padding: 0 1rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(var(--primColor), 1);
  position: absolute;
  top: 2rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.divider {
  width: 90%;
  height: 1px;
  background-color: #aaa;
}

.sideBar {
  padding: 0;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(12, 75, 140, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(12, 75, 140, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(12, 75, 140, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="rgba(var(--primColor), 1)",GradientType=1);
}

.sideBar ul {
  margin: 1rem 0;
  padding: 0;
  /* background-color: white; */
  position: relative;
}

.sideBar ul li {
  list-style: none;
  position: relative;
  width: 100%;
  position: relative;
  background: #0d4b8c;
  background: -webkit-linear-gradient(right, #0d4b8c, #ccc);
  background: -moz-linear-gradient(right, #0d4b8c, #ccc);
  background: linear-gradient(to left, #0d4b8c, #ccc);
  text-align: right;
  margin: 0.1rem 0 auto;
  font-weight: 600;
  padding: 0.2rem;
  padding-right: 1rem;
  transition: 0.5s;
  cursor: pointer;
}

.jumbotron {
  padding: 0 !important;
  margin-top: 5.5vh;
}

.chapter {
  text-align: right;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  transition: 0.5s;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  /* align-items: flex-start; */
}

.chapter span {
  padding: 0.25rem 0.5rem;
}

.closeBtn {
  border-radius: 50%;
  height: auto;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0.5rem !important;
  background-color: rgba(255, 0, 0, 0.8);
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  transition: 0.3s;
}

.closeBtn:hover {
  background-color: red;
  transition: 0.3s;
}

.closeBtn:active {
  background-color: darkred !important;
}

.smTopBtn {
  height: 0.2rem;
  width: 0.2rem;
  top: -0.25rem;
  right: -0.25rem;
}

.nav-link .active {
  color: goldenrod;
}

.circleProgressBox {
  border-right: 2px solid;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 992px) {
  .wizard {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .circleProgressBox {
    border-right: none;
  }
}

@media only screen and (max-width: 600px) {
  select,
  input {
    max-width: 95%;
  }

  h5 {
    font-size: 1rem;
  }

  .mainContent {
    max-height: 92vh;
    overflow: hidden;
  }
  .toastHolder {
    top: 6rem !important;
  }

  .navbar-brand {
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 1.1rem;
    padding: 0;
  }

  .wizard {
    width: 100%;
    height: auto;
    max-height: 80vh;
    margin: 0;
  }

  .sideBar {
    display: none;
  }

  .closeBtn {
    display: none;
  }

  .college {
    margin: 0;
    padding: 0.25rem;
    margin-top: 0.5rem;
  }

  .collegeRow {
    padding: 0;
    margin-top: 2rem;
  }

  .winForm {
    max-width: 95% !important;
    margin-top: 3rem;
  }

  .wizard .paper {
    border: none;
    box-shadow: none;
    margin: 0.25rem 0 auto;
    width: 100%;
  }

  .college .paper {
    width: 100% !important;
    margin: 0 auto !important;
    border-radius: none;
  }
}

@media only screen and (max-height: 360px) {
  .toastHolder {
    top: 6rem !important;
  }

  .navbar-brand {
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 1.1rem;
    padding: 0;
  }

  .wizard {
    width: 100%;
    height: auto;
    margin: 3rem 0;
  }

  .sideBar {
    display: none;
  }

  .closeBtn {
    display: none;
  }

  .innerMainContent {
    padding-top: 2rem;
  }

  .titleBar {
    display: none;
  }

  .sectionTitle {
    display: none;
  }
}
