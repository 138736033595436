.fileUpload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileUploadButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #0c4b8c;
  border-radius: 0.1rem;
}

.fileUploadButton svg {
  font-size: 3rem;
  color: #333;
  border: 1px;
}

.fileUploadButton:hover > svg {
  color: #0c4b8c;
  transition: 0.5s;
}

svg {
  color: #1e66e8;
}

.thumbChooser {
  width: 100%;
  height: 12rem;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.singleThumb {
  height: 80%;
  margin: 0.2rem;
  border: 3px solid #0c4b8c;
  border-radius: 5px;
  padding: 0.2rem;
  transition: 0.5s;
}

.singleThumb:hover {
  border-color: #0c4b8c;
  height: 95%;
  transition: 0.5s;
}

.uploadThumb {
  min-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #0c4b8c;
}

.uploadThumb p {
  text-align: center;
}

.uploadIcon {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #0c4b8c;
}

.uploadIcon p {
  text-align: center;
}

.uploadThumb:hover {
  height: 80%;
}

.vidEditContainer {
  border: 2px solid transparent;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  overflow: hidden;
}
